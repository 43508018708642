import {Suspense} from 'react';

import AddToCartVariantsContainer from 'Component/AddToCartVariants';
import VariantProductListComponent from 'Component/VariantProductList';
import {CategoryPageLayout} from 'Route/CategoryPage/CategoryPage.config';
import {
    AddToCart,
    FieldContainer,
    GroupedProductList,
    ProductBundleOptions,
    ProductCompareButton,
    ProductComponent as SourceProductComponent,
    ProductConfigurableAttributes as SourceProductConfigurableAttributes,
    ProductCustomizableOptions as SourceProductCustomizableOptions,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductReviewRating,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';
import {ReactElement} from 'Type/Common.type';

import './Product.override.style';

export {
    ProductReviewRating,
    AddToCart,
    FieldContainer,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
    ProductComponent as SourceProductComponent,
};

// TODO: implement ProductConfigurableAttributes
export const ProductConfigurableAttributes = SourceProductConfigurableAttributes;

// TODO: implement ProductCustomizableOptions
export const ProductCustomizableOptions = SourceProductCustomizableOptions;

/** @namespace VusaApp/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    renderQuantityChanger(): ReactElement {
        const { product: { variants = [] } } = this.props;

        if (variants.length > 0) {
            return null;
        }

        return super.renderQuantityChanger();
    }

    renderBrand(): ReactElement {
        return null;
    }

    renderPrice(): ReactElement {
        return null;
    }

    renderConfigurableOptions(): ReactElement {
        const { setActiveProduct, inStock, product: { sku, variants = [], configurable_options = [] } } = this.props;

        if (!variants.length || !inStock) {
            return super.renderConfigurableOptions();
        }

        return (
            <div>
                <VariantProductListComponent
                  sku={ sku }
                  items={ variants }
                  configurableOptions={ configurable_options }
                  setActiveProduct={ setActiveProduct }
                />
            </div>
        );
    }

    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
            product: { variants = [] },
        } = this.props;

        if (variants.length > 0) {
            return (
            <Suspense fallback={ null }>
                <AddToCartVariantsContainer
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
            );
        }

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }
}

export default ProductComponent;
