import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import {ReactElement} from 'Type/Common.type';

import './ProductCard.override.style';

/** @namespace VusaApp/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderBrand(): ReactElement {
        return (<div />);
    }
}

export default ProductCardComponent;
