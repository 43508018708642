import PropTypes from 'prop-types';
import React from 'react';

import './VariantProductList.style';

/** @namespace VusaApp/Component/VariantProductList/Component */
export class VariantProductListComponent extends React.Component {
    static propTypes = {
        items: PropTypes.arrayOf(Object).isRequired,
        configurableOptions: PropTypes.arrayOf(Object).isRequired,
        productQuantities: PropTypes.arrayOf(Object).isRequired,
        quantityChangeHandler: PropTypes.func.isRequired,
        clickActiveProduct: PropTypes.func.isRequired,
    };

    render() {
        // eslint-disable-next-line max-len
        const {
            clickActiveProduct, items, configurableOptions, productQuantities, quantityChangeHandler,
        } = this.props;
        const attributes = Object.keys(configurableOptions);

        return (
            <div block="table-responsive">
            <table block="table">
                <thead>
                    { attributes.map((item) => <th key={ item }>{ configurableOptions[item].attribute_label }</th>) }
                    <th>Avability</th>
                    <th>Unit Price</th>
                    <th>Subtotal</th>
                    <th>Qty</th>
                </thead>
                <tbody>
                { items.map((item) => (
                    <tr key={ item.id }>
                        { attributes.map((attribute, index) => {
                            if (!item?.attributes[attribute]?.attribute_value) {
                                return <td />;
                            }

                            return (
                                <td>
                                    { /* eslint-disable-next-line max-len */ }
                                    <button
                                      block={ index === 0 ? 'btn' : 'btn-secondary' }
                                      onClick={ clickActiveProduct }
                                      data-code={ attribute }
                                      data-value={ item.attributes[attribute].attribute_value ?? '' }
                                    >
                                        { /* eslint-disable-next-line max-len,@scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                                        { item.attributes[attribute] ? configurableOptions[attribute].attribute_options[item.attributes[attribute].attribute_value].label : '' }
                                    </button>
                                </td>
                            );
                        }) }
                        <td>
                            { item.salable_qty }
                        </td>
                        <td>
                            $
                            { item.price_range.maximum_price.default_final_price.value }
                        </td>
                        <td>
                            $
                            { /* eslint-disable-next-line max-len */ }
                            { (item.price_range.maximum_price.default_final_price.value * productQuantities[item.id]).toFixed(2) }
                        </td>
                        <td>
                            <input
                              type="number"
                              value={ productQuantities[item.id] }
                              data-id={ item.id }
                              onChange={ quantityChangeHandler }
                              style={ { width: '70px', textAlign: 'center' } }
                              disabled={ item.salable_qty === 0 }
                            />
                        </td>
                    </tr>
                )) }
                </tbody>
            </table>
            </div>
        );
    }
}

export default VariantProductListComponent;
