import {
    CategoryDetailsComponent as SourceCategoryDetailsComponent,
} from 'SourceComponent/CategoryDetails/CategoryDetails.component';
import { ReactElement } from 'Type/Common.type';

import './CategoryDetails.override.style';
/** @namespace VusaApp/Component/CategoryDetails/Component */
export class CategoryDetailsComponent extends SourceCategoryDetailsComponent {
    render() : ReactElement {
        return (
            <article block="CategoryDetails">
                <div block="CategoryDetails" elem="Description">
                    { this.renderCategoryName() }
                    { this.renderCategoryImage() }
                    { this.renderCategoryDescription() }
                </div>
            </article>
        );
    }
}

export default CategoryDetailsComponent;
