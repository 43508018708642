import { ComponentType } from 'react';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';

import { ProductContainerProps } from './Product.type';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace VusaApp/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(
    ProductContainer as unknown as ComponentType<ProductContainerProps>,
);
