import { encodeBase64 } from '@scandipwa/scandipwa/src/util/Base64/Base64';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import VariantProductListComponent from './VariantProductList.component';

/** @namespace VusaApp/Component/VariantProductList/Container */
export class VariantProductListContainer extends Component {
    static propTypes = {
        sku: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(Object).isRequired,
        configurableOptions: PropTypes.arrayOf(Object).isRequired,
        setActiveProduct: PropTypes.func.isRequired,
    };

    containerFunctions = {
        quantityChangeHandler: this.quantityChangeHandler.bind(this),
        clickActiveProduct: this.clickActiveProduct.bind(this),
    };

    __construct(props) {
        super.__construct(props);
        const { items } = props;
        this.state = {
            productQuantities: items.reduce((acc, curr) => {
                acc[curr.id] = 0;

                return acc;
            }, {}),
        };
        this.quantityChangeHandler = this.quantityChangeHandler.bind(this);
    }

    clickActiveProduct(event) {
        const { setActiveProduct } = this.props;
        const attribute_code = event.target.attributes['data-code'].value;
        const attribute_value = event.target.attributes['data-value'].value;

        setActiveProduct(attribute_code, attribute_value);
    }

    quantityChangeHandler(event) {
        const { sku, items } = this.props;
        this.setState((prevState) => {
            const itemId = event.target.attributes['data-id'].value;
            const item = items.find((v) => v.id === Number(itemId));
            const updatedQuantities = { ...prevState.productQuantities };
            let newQty = Number(event.target.value);

            if (newQty > item.salable_qty) {
                newQty = item.salable_qty;
            }

            if (newQty < 0) {
                newQty = 0;
            }
            updatedQuantities[itemId] = newQty;
            // get only the values which is bigger then zero
            const filteredQuantities = Object.keys(updatedQuantities).reduce((acc, curr) => {
                if (updatedQuantities[curr] > 0) {
                    acc[curr] = {};
                    acc[curr].quantity = updatedQuantities[curr];
                    acc[curr].sku = sku;
                    acc[curr].selected_options = this.getSelectedOptions(curr);
                    acc[curr].entered_options = this.getEnteredOptions(curr);
                }

                return acc;
            }, {});
            window.productQuantities = filteredQuantities;

            return { productQuantities: updatedQuantities };
        });
    }

    getSelectedOptions(itemId) {
        const { items, configurableOptions } = this.props;
        const attributes = Object.keys(configurableOptions);
        const selected_options = [];
        const item = items.find((v) => v.id === Number(itemId));
        attributes.forEach((attribute) => {
            if (!item?.attributes[attribute]?.attribute_value) {
                return;
            }
            // eslint-disable-next-line max-len
            selected_options.push(encodeBase64(`configurable/${item.attributes[attribute].attribute_id}/${item.attributes[attribute].attribute_value}`));
        });

        return selected_options;
    }

    getEnteredOptions(itemId) {
        const { items, configurableOptions } = this.props;
        const attributes = Object.keys(configurableOptions);
        const entered_options = [];
        const item = items.find((v) => v.id === Number(itemId));
        attributes.forEach((attribute) => {
            if (!item?.attributes[attribute]?.attribute_value) {
                return;
            }
            // eslint-disable-next-line max-len
            entered_options.push({ uid: item.attributes[attribute].attribute_id, value: item.attributes[attribute].attribute_value });
        });

        return entered_options;
    }

    render() {
        const { items, configurableOptions } = this.props;
        const { productQuantities } = this.state;

        return (
                <VariantProductListComponent
                  { ...this.containerFunctions }
                  items={ items }
                  configurableOptions={ configurableOptions }
                  productQuantities={ productQuantities }
                />
        );
    }
}

export default VariantProductListContainer;
